import Header from "../header";
import Slider from "../slider";
import AboutUs from "../about-us";

function App() {
  return (
    <>
      <Header/>
      <Slider/>
      <AboutUs/>
    </>
  
  );
}

export default App;
